import React from 'react';

export const FEATURES_DATA = [
  {
    illustration: (
      <img
        width={160}
        src="/features/built-for-speed.png"
        srcSet="/features/built-for-speed.png 1x, /features/built-for-speed@2x.png 2x"
        alt="Feature"
        loading="lazy"
      />
    ),
    content: 'Spend less time creating & explaining. Simplify how you communicate your thoughts.',
    title: 'Built for speed',
  },
  {
    illustration: (
      <img
        width={160}
        src="/features/zero-learning-curve.png"
        srcSet="/features/zero-learning-curve.png 1x, /features/zero-learning-curve@2x.png 2x"
        alt="Feature"
        loading="lazy"
      />
    ),
    content: 'Crank out your first sketch in no time, without reading User Guides or watching tutorials.',
    title: 'Zero learning curve',
  },
  {
    illustration: (
      <img
        width={160}
        src="/features/no-wifi.png"
        srcSet="/features/no-wifi.png 1x, /features/no-wifi@2x.png 2x"
        alt="Feature"
        loading="lazy"
      />
    ),
    content: 'SketchWow software installs on your PC or Mac. No more lost work when your Internet connection times out.',
    title: 'No WiFi? No problem',
  },
];
