import React from 'react';

export const CAROUSEL_DATA = [
  {
    title: 'Diagrams & Flows',
   // title: '1 Page Plan',
    illustration: (
      <img
        width={700}
        // src="/carousel/diagrams-flows.webp"
        // srcSet="/carousel/diagrams-flows.webp 1x, /carousel/diagrams-flows@2x.webp 2x"
        src="/carousel/Diagrams-Flows-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-1.webp',
    // previewUrl: '/carousel/d1-Page Plans-min.jpg',
  },
  {
    title: 'UI Wireframes',
    //title: 'Advertisement',
    illustration: (
      <img
        width={700}
        // src="/carousel/ui-wireframes.webp"
        // srcSet="/carousel/ui-wireframes.webp 1x, /carousel/ui-wireframes@2x.webp 2x"
        src="/carousel/UI-Wireframes-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-2.webp',
    //previewUrl: '/carousel/Advertisements-min.jpg',
  },
  {
    title: 'Brainstorm',
    //title: 'Empathy Map',
    illustration: (
      <img
        width={700}
        // src="/carousel/brainstorm.webp"
        // srcSet="/carousel/brainstorm.webp 1x, /carousel/brainstorm@2x.webp 2x"
        src="/carousel/Brainstorm-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-3.webp',
    //previewUrl: '/carousel/Empathy Maps-min.jpg',
  },
  {
    title: 'Process Map',
    //title: 'Flowchart',
    illustration: (
      <img
        width={700}
        // src="/carousel/process-map.webp"
        // srcSet="/carousel/process-map.webp 1x, /carousel/process-map@2x.webp 2x"
        src="/carousel/Process-Map-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-4.webp',
    //previewUrl: '/carousel/Flowcharts-min.jpg',
  },
  {
    title: 'Social Content',
    //title: 'Fun Comic Strip',
    illustration: (
      <img
        width={700}
        // src="/carousel/social-content.webp"
        // srcSet="/carousel/social-content.webp 1x, /carousel/social-content@2x.webp 2x"
        src="/carousel/Social-Content-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-5.webp',
    //previewUrl: '/carousel/Fun Comic Strips-min.jpg',
  },
  {
    title: 'Infographics',
    //title: 'Org Chart',
    illustration: (
      <img
        // src="/carousel/infographics.webp"
        // srcSet="/carousel/infographics.webp 1x, /carousel/infographics@2x.webp 2x"
        src="/carousel/Infographics-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel-preview-6.webp',
    //previewUrl: '/carousel/Org Charts-min.jpg',
  },
  {
    title: 'Journeys',
    //title: 'SWOT Analysis',
    illustration: (
      <img src="/carousel/Journeys-min.jpg" /*src="/carousel/journeys.webp" srcSet="/carousel/journeys.webp 1x, /carousel/journeys@2x.webp 2x"*/  alt="Item" loading="lazy" />
    ),
    previewUrl: '/carousel-preview-7.webp',
    //previewUrl: '/carousel/SWOT Analysis-min.jpg',
  },
  {
    
   title: '1 Page Plan',
    illustration: (
      <img
        width={700}
        // src="/carousel/diagrams-flows.webp"
        // srcSet="/carousel/diagrams-flows.webp 1x, /carousel/diagrams-flows@2x.webp 2x"
        src="/carousel/1-Page Plans-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
   previewUrl: '/carousel/1-Page Plans-min.jpg',
  },
  {
    title: 'Advertisement',
    illustration: (
      <img
        width={700}
        // src="/carousel/ui-wireframes.webp"
        // srcSet="/carousel/ui-wireframes.webp 1x, /carousel/ui-wireframes@2x.webp 2x"
        src="/carousel/Advertisement.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/Advertisement.png',
  },
  {
    title: 'Empathy Map',
    illustration: (
      <img
        width={700}
        // src="/carousel/brainstorm.webp"
        // srcSet="/carousel/brainstorm.webp 1x, /carousel/brainstorm@2x.webp 2x"
        src="/carousel/Empathy Maps-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
   previewUrl: '/carousel/Empathy Maps-min.jpg',
  },
  {
    title: 'Flowchart',
    illustration: (
      <img
        width={700}
        // src="/carousel/process-map.webp"
        // srcSet="/carousel/process-map.webp 1x, /carousel/process-map@2x.webp 2x"
        src="/carousel/Flowcharts-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/Flowcharts-min.jpg',
  },
  {
    title: 'Fun Comic Strip',
    illustration: (
      <img
        width={700}
        // src="/carousel/social-content.webp"
        // srcSet="/carousel/social-content.webp 1x, /carousel/social-content@2x.webp 2x"
        src="/carousel/Fun Comic Strips-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/Fun Comic Strips-min.jpg',
  },
  {
    title: 'Org Chart',
    illustration: (
      <img
        // src="/carousel/infographics.webp"
        // srcSet="/carousel/infographics.webp 1x, /carousel/infographics@2x.webp 2x"
        src="/carousel/Org Charts-min.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/Org Charts-min.jpg',
  },
  {
    title: 'SWOT Analysis',
    illustration: (
      <img src="/carousel/SWOT Analysis-min.jpg" /*src="/carousel/journeys.webp" srcSet="/carousel/journeys.webp 1x, /carousel/journeys@2x.webp 2x"*/  alt="Item" loading="lazy" />
    ),
    previewUrl: '/carousel/SWOT Analysis-min.jpg',
  },
  // {
  //   title: 'Advertisement',
  //   illustration: (
  //     <img src="/carousel/Advertisement.png" /*src="/carousel/journeys.webp" srcSet="/carousel/journeys.webp 1x, /carousel/journeys@2x.webp 2x"*/  alt="Item" loading="lazy" />
  //   ),
  //   previewUrl: '/carousel/Advertisement.png',
  // },
  {
    title: 'Animated Diagrams',
    illustration: (
      <img src="/carousel/Animated_Diagrams.gif" /*src="/carousel/journeys.webp" srcSet="/carousel/journeys.webp 1x, /carousel/journeys@2x.webp 2x"*/  alt="Item" loading="lazy" />
    ),
    previewUrl: '/carousel/Animated_Diagrams.gif',
  },
];


export const CAROUSEL_DATA1 = [
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/10 Small Tips.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/10 Small Tips.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/danish-visual.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/danish-visual.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/data-science-journey.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/data-science-journey.gif', 
  },
  {
    //desc: 'What will you design using SketchWow? Drop your unique designs into a PDF, handout, ebook, presentation, social post, how to guide, internal doc marketing materials, blog post, meeting notes… nlnl You\'ll never run out of creative ways to use SketchWow.', 
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/EZ Messenger.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/EZ Messenger.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/animated-sketch.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/animated-sketch.gif', 
  },
  // {
  //   illustration: (
  //     <img
  //       width={700} 
  //       src="/carousel/customers_sketches/Caricture-humor.png"
  //       alt="Item"
  //       loading="lazy"
  //     />
  //   ),
  //   previewUrl: '/carousel/customers_sketches/previews/Caricture-humor.png', 
  // },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/chocolate.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/chocolate.png', 
  },
  // {
  //   illustration: (
  //     <img
  //       width={700} 
  //       src="/carousel/customers_sketches/creating-customer-personas.png"
  //       alt="Item"
  //       loading="lazy"
  //     />
  //   ),
  //   previewUrl: '/carousel/customers_sketches/previews/creating-customer-personas.png', 
  // },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/danish-sketch.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/danish-sketch.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/doctor-animation.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/doctor-animation.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Finance-transformation.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Finance-transformation.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/french-visual.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/french-visual.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Fueling Swimmers.png"
        alt="Item"
        loading="lazy"
        className='rs'
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Fueling Swimmers.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/german-infographic.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/german-infographic.jpeg', 
  },
  // {
  //   illustration: (
  //     <img
  //       width={700} 
  //       src="/carousel/customers_sketches/hand-drawn-gif.gif"
  //       alt="Item"
  //       loading="lazy"
  //     />
  //   ),
  //   previewUrl: '/carousel/customers_sketches/previews/hand-drawn-gif.gif', 
  // },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/High-level.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/High-level.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Ignore-haters.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Ignore-haters.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/infographic.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/infographic.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Manhood-Manual.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Manhood-Manual.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Marketing Tech.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Marketing Tech.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/NAR Settlement.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/NAR Settlement.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Nuclear War Prevention.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Nuclear War Prevention.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Organic Search Principles.png"
        alt="Item"
        loading="lazy"
        className='rs1'
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Organic Search Principles.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/photo-sketch.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/photo-sketch.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Problem Solving.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Problem Solving.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Reshoring-considerations.gif"
        alt="Item"
        loading="lazy"
        className='rs'
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Reshoring-considerations.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Retrospective-German.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Retrospective-German.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Scrum-German.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Scrum-German.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/Single-sign-on.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/Single-sign-on.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/spanish-brainstorm.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/spanish-brainstorm.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/tennant-app.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/tennant-app.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/top5-clifton-strengths.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/top5-clifton-strengths.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/train-chatgpt.gif"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/train-chatgpt.gif', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/understanding-fasd.png"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/understanding-fasd.png', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/unicorn-venn.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/unicorn-venn.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/your-personal-brand.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/your-personal-brand.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/timeless-startup-tips.jpg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/timeless-startup-tips.jpg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/telling_stories_French.jpeg"
        alt="Item"
        loading="lazy"
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/telling_stories_French.jpeg.jpeg', 
  },
  {
    illustration: (
      <img
        width={700} 
        src="/carousel/customers_sketches/practicing-presentations.png"
        alt="Item"
        loading="lazy"
        className='rs1'
      />
    ),
    previewUrl: '/carousel/customers_sketches/previews/practicing-presentations.png', 
  }, 
];
