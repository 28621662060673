import React, { ReactElement, ReactNode, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useWindowSize } from 'react-use';
import * as Styled from './styles';
import { CarouselItem } from './types';
import IconPagination from '../../assets/icon-pagination.inline.svg';
import IconPaginationPrevious from '../../assets/icon-pagination-previous.inline.svg';
import IconPaginationNext from '../../assets/icon-pagination-next.inline.svg';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { Modal } from '../../components/Modal/Modal';

interface CarouselProps {
  data: CarouselItem[];
  title: string | ReactNode;
  subTitle: string | ReactNode;
  note: string | ReactNode;
  showWowIcon: boolean;
  sketch: string | ReactNode;
  carouselType: string | ReactNode;
}

interface CarouselDataItem extends CarouselItem {
  id: string;
}

//const ITEM_WIDTH = 313;
const ITEM_WIDTH = 356;

export const Carousel = ({ data, subTitle, title, showWowIcon, note, sketch, carouselType }: CarouselProps): ReactElement => {
  const carouselRef = useRef(null);
  const { isVisible } = useVisibilityDetection(carouselRef);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const { width } = useWindowSize(0, 0);
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): CarouselDataItem => ({
          ...item,
          id: uniqueId(),
        }),
      ),
    [data],
  );

  const [activeSlide, setActiveSlide] = useState(memoizedData[0].id);
 
  const carouselWidth = useMemo(() => ITEM_WIDTH * memoizedData.length, []);

  const getFulfillmentItemsOnSide = useCallback((visibleItems) => Math.ceil((visibleItems - 1) / 2) + 3, []);

  const getActiveSlideIndex = useCallback(() => memoizedData.findIndex(({ id }) => id === activeSlide), [activeSlide]);

  const getItemsVisibleInViewport = useCallback(() => width / ITEM_WIDTH, [width]);

  const getCarouselWrapperPosition = useCallback(() => {
    const visibleItems = getItemsVisibleInViewport();
    const fulfillmentItemsOnSide = getFulfillmentItemsOnSide(visibleItems);
    const centerXPoint = Math.round(width / 2);
    const initialItemXPoint = fulfillmentItemsOnSide * ITEM_WIDTH;
    const initialNegativeValue = Math.round(initialItemXPoint + ITEM_WIDTH / 2 - centerXPoint);

    return -(initialNegativeValue + ITEM_WIDTH * getActiveSlideIndex());
  }, [getItemsVisibleInViewport, getActiveSlideIndex, width]);

  const fulfillmentArray = useMemo(
    () => [...new Array(getFulfillmentItemsOnSide(getItemsVisibleInViewport()))],
    [getFulfillmentItemsOnSide, getItemsVisibleInViewport],
  );
  const leftFilledItems = useMemo(
    () =>
      fulfillmentArray.map((_, index) => {
        const currentItem = memoizedData[memoizedData.length - (index + 1)];
       

        if(currentItem){
          return {
            ...currentItem,
            id: `${currentItem.id}-before`,
          };
        }
      }),
    [fulfillmentArray],
  );
  const rightFilledItems = useMemo(
    () =>
      fulfillmentArray.map((_, index) => {
        const currentItem = memoizedData[index];

        return {
          ...currentItem,
          id: `${currentItem.id}-after`,
        };
      }),
    [fulfillmentArray],
  );

  const handlePrevClick = useCallback(() => {
    const activeSlideIndex = getActiveSlideIndex();

    if (activeSlideIndex === 0) {
      setActiveSlide(memoizedData[memoizedData.length - 1].id);
    } else {
      setActiveSlide(memoizedData[activeSlideIndex - 1].id);
    }
  }, [activeSlide, getActiveSlideIndex]);

  const handleNextClick = useCallback(() => {
    const activeSlideIndex = getActiveSlideIndex();
    
    if (activeSlideIndex === memoizedData.length - 1) {
      setActiveSlide(memoizedData[0].id);
    } else {
      setActiveSlide(memoizedData[activeSlideIndex + 1].id);
    }
  }, [activeSlide, getActiveSlideIndex]);

  const handleOpenPreview = useCallback((prevTitle: string, prevUrl: string) => {
    setIsPreviewVisible(true);
    setPreviewTitle(prevTitle);
    setPreviewUrl(prevUrl);
  }, []);

  const handleClosePreview = useCallback(() => {
    setIsPreviewVisible(false);
  }, []);



  useEffect(()=>{
    const clickNext = (() =>{
      if(sketch == "customer")
        document.getElementById("next-sketch").click();
      else
        document.getElementById("next").click();
      setTimeout(function(){
        clickNext()
      }, 4000);
    });

    setTimeout(function(){
      clickNext()
    }, 4000);
   }, []);

  return (
    <>
      <Styled.SafariWrapper showWowIcon={showWowIcon} key={carouselType}>
        <Styled.Wrapper isVisible={isVisible} ref={carouselRef}>
          <Styled.Heading> 
            {typeof note != 'undefined' &&   <Styled.Note><strong>Note:</strong> {note}</Styled.Note> }
            <Styled.Title>{title}</Styled.Title>
            <Styled.SubTitle>{subTitle}</Styled.SubTitle>
            
          </Styled.Heading>
          {width && (
            <>
              <Styled.Carousel sketch={sketch}>
                <Styled.CarouselWrapper
                  style={{
                    width: carouselWidth,
                    transform: `translateX(${getCarouselWrapperPosition()}px)`,
                  }}
                >
                  {leftFilledItems.map(({ id, illustration, title: itemTitle, desc }) => (
                    <Styled.CarouselItem isActive={false} key={id} sketch={sketch}>
                      {illustration}
                      <Styled.CarouselItemTitle key={carouselType+id}>{itemTitle}</Styled.CarouselItemTitle>
                      {/* {typeof desc != 'undefined' && <Styled.CarouselItemDesc>
                        {String(desc).split("nl").map(function(t) {
                          return (
                           <>{t} <br/></>
                          )
                        })}
                      </Styled.CarouselItemDesc>} */}
                    </Styled.CarouselItem>
                  ))}
                  {memoizedData.map(({ id, illustration, previewUrl, title: itemTitle, desc }) => {
                    const isActive = id === activeSlide;

                    const handleClick = useCallback(() => {
                      if (isActive && sketch != "customer") {
                        handleOpenPreview(itemTitle, previewUrl);
                      }
                    }, [isActive]);

                    return (
                      <Styled.CarouselItem isActive={isActive} key={id} onClick={handleClick}  sketch={sketch}>
                        {illustration}
                        <Styled.CarouselItemTitle key={carouselType+id}>{itemTitle}</Styled.CarouselItemTitle>
                        {/* {typeof desc != 'undefined' && <Styled.CarouselItemDesc>
                        {String(desc).split("nl").map(function(t) {
                          return (
                           <>{t} <br/></>
                          )
                        })}
                      </Styled.CarouselItemDesc>} */}
                      </Styled.CarouselItem>
                    );
                  })}
                  {rightFilledItems.map(({ id, illustration, title: itemTitle, desc }) => (
                    <Styled.CarouselItem isActive={false} key={id}  sketch={sketch}>
                      {illustration}
                      <Styled.CarouselItemTitle key={carouselType+id}>{itemTitle}</Styled.CarouselItemTitle>
                      {/* {typeof desc != 'undefined' && <Styled.CarouselItemDesc>
                        {String(desc).split("nl").map(function(t) {
                          return (
                           <>{t} <br/></>
                          )
                        })}
                      </Styled.CarouselItemDesc>} */}
                    </Styled.CarouselItem>
                  ))}
                </Styled.CarouselWrapper>
              </Styled.Carousel>
              <Styled.CarouselNav>
                <Styled.CarouselNavButtonLeft  sketch={sketch} type="button" onClick={handlePrevClick} aria-label="Go to previous slide">
                  <IconPaginationPrevious />
                </Styled.CarouselNavButtonLeft>
                {memoizedData.map(({ id }, index) => (
                  <Styled.CarouselNavDot
                    sketch={sketch}
                    isActive={id === activeSlide}
                    key={id}
                    onClick={() => setActiveSlide(id)}
                    type="button"
                    aria-label={`Go to slide ${index + 1}`}
                  >
                    <IconPagination />
                  </Styled.CarouselNavDot>
                ))}
                <Styled.CarouselNavButtonRight  sketch={sketch} id={(sketch == "customer") ? "next-sketch" : "next"} type="button" onClick={handleNextClick} aria-label="Go to next slide">
                  <IconPaginationNext />
                </Styled.CarouselNavButtonRight>
              </Styled.CarouselNav>
            </>
          )}
        </Styled.Wrapper>
      </Styled.SafariWrapper>
      <Modal illustration={previewUrl} isOpen={isPreviewVisible} onDismiss={handleClosePreview} title={previewTitle} />
    </>
  );
};
