import React, { ReactElement } from 'react';
import { css } from '@emotion/react';

interface ModalContentIllustrationProps {
  title: string;
  illustration: string;
}

export const ModalContentIllustration = ({ illustration, title }: ModalContentIllustrationProps): ReactElement => (
  <svg
    viewBox="0 0 1124 629"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    css={css`
      filter: drop-shadow(0 80px 160px rgba(0, 0, 0, 0.1));
    `}
  >
    <defs>
      <path
        d="M69.0601968,497.708206 C55.9240792,492.267665 48.1531443,483.748209 45.7473924,472.149837 C30.2055228,248.692276 30.2055228,115.786561 45.7473924,73.4326925 C61.289262,31.0788239 383.968079,22.0725415 1013.78384,46.4138453 C1064.84999,48.3611496 1090.01301,56.6371929 1089.27292,71.2419752 C1088.16279,93.1491486 1101.48439,410.079512 1077.06145,491.866293 C1066.94226,525.753138 796.918401,528.787222 556.408823,528.378249 C460.242876,528.214724 319.996005,521.885985 135.668209,509.392032 C104.398985,507.043355 82.1963145,503.148746 69.0601968,497.708206 Z"
        id="path-1"
      />
      <pattern id="pattern-2" width="2116" height="991" x="-2081.90901" y="-957.911686" patternUnits="userSpaceOnUse">
        <use xlinkHref="#image-3" />
      </pattern>
      <image id="image-3" width="1058" height="496" xlinkHref={illustration} />
    </defs>
    <g id="Landing-page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Preview" transform="translate(-158.000000, -48.000000)">
        <g id="Flows-preview" transform="translate(158.000000, 48.000000)">
          <path
            d="M37.1568537,589.709863 C23.1989505,582.804561 14.9418719,571.991405 12.3856179,557.270394 C-4.1285393,273.651182 -4.1285393,104.96316 12.3856179,51.2063266 C28.8997751,-2.55050669 371.765134,-13.9815574 1040.98169,16.9131743 C1095.2425,19.3847529 1121.9797,29.8889616 1121.19332,48.4258007 C1120.01373,76.2310593 1134.16872,478.488829 1108.21791,582.295127 C1097.46566,625.305353 810.549315,629.156306 554.99364,628.637225 C452.811625,628.429674 303.791016,620.397044 107.931813,604.539334 C74.7064101,601.558321 51.1147569,596.615164 37.1568537,589.709863 Z"
            id="box"
            fill="#FFFFFF"
          />
          <text id={title} fontFamily="Inter-Black, Inter" fontSize="32" fontWeight="800" line-spacing="32" fill="#192A4A">
            <tspan text-anchor="middle" x="50%" y="593.544157">
              {title}
            </tspan>
          </text>
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <use id="Mask" fill="url(#pattern-2)" xlinkHref="#path-1" />
        </g>
      </g>
    </g>
  </svg>
);
