import React, { ReactElement, ReactNode, useMemo, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';
import { FeaturesItem } from './types';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

interface FeaturesProps {
  title: string | ReactNode;
  data: FeaturesItem[];
}

interface FeaturesDataItem extends FeaturesItem {
  id: string;
}

export const Features = ({ title, data }: FeaturesProps): ReactElement => {
  const memoizedData = useMemo(
    () =>
      data.map(
        (item): FeaturesDataItem => ({
          ...item,
          id: uniqueId(),
        }),
      ),
    [data],
  );

  const featuresRef = useRef(null);
  const { isVisible } = useVisibilityDetection(featuresRef);

  return (
    <Styled.Wrapper isVisible={isVisible} ref={featuresRef}>
      <Styled.Heading>
        <Styled.Title>{title}</Styled.Title>
      </Styled.Heading>
      <Styled.List>
        {memoizedData.map(({ illustration, content, id, title }) => (
          <Styled.ListItem key={id}>
            <Styled.ListFigure>{illustration}</Styled.ListFigure>
            <Styled.ListTitle>{title}</Styled.ListTitle>
            <Styled.ListContent>{content}</Styled.ListContent>
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Wrapper>
  );
};
