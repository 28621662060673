import styled from '@emotion/styled';
import { FeatureDirection } from './types';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from '../../types';

interface FeatureSection extends Visibility {
  alignment: FeatureDirection;
}

const FlexDirectionByAlignment: Record<FeatureDirection, string> = {
  normal: 'row',
  reversed: 'row-reverse',
};

export const FeatureSection = styled.section<FeatureSection>`
  
  display: flex;
  max-width: ${1110 + dimensions.containerPadding * 2}px;
  margin: 0 auto 28px auto;
  //padding: 0 ${dimensions.containerPadding}px;
  flex-direction: ${({ alignment }) => FlexDirectionByAlignment[alignment]};
  align-items: center;
  justify-content: space-between;

  /*opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-top: -40px;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    flex-direction: column-reverse;
  }
`;

export const ContentSection = styled.div<FeatureSection>`
  max-width: 542px;
  ${({ alignment }) => alignment === 'reversed' && 'padding-right: 10px;'}
  @media screen and (max-width: 1279px) {
    padding-left: 30px;

  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    max-width: 460px;
    flex-shrink: 0;
    ${({ alignment }) =>
      alignment === 'reversed'
        ? `
        padding-right: 0;

        @media screen and (max-width: ${breakpoints.lg}px) {
          padding-left: 0;
        }
      `
        : `
        padding-right: 30px;

        @media screen and (max-width: ${breakpoints.lg}px) {
          padding-right: 0;
        }
      `}
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    padding-left:30px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    padding-left:0px;
  }
`;

export const Heading = styled.header`
  margin-bottom: 34px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: ${dimensions.fontSize.heading}px;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: 34px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
    margin-top:20px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:21px;
  }
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: ${dimensions.lineHeight.medium};

  > * {
    margin: 16px 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;

  }
 
`;

export const Figure = styled.figure`
  margin: 0;
  padding: 0;
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom:30px;
  }


  @media screen and (max-width: ${breakpoints.xl}px) {
    img {
      margin-left: 5px;
      max-width: 100%;
      min-width: 50%;
    }
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      margin-left: 0px;
      max-width: 300px;
    }
  }
`;
