import styled from '@emotion/styled';
import { breakpoints, dimensions } from '../../styles/variables';

interface SectionProps {
  maxWidth?: number;
}



export const Section = styled.section<SectionProps>`
  width: 100%;
  max-width: ${({ maxWidth = 864 }) => maxWidth}px;
  margin: 0 auto;
  padding: 0 ${dimensions.containerPadding}px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    display: flex;
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

export const LeftColumn = styled.div``;

export const RightColumn = styled.div``;


