import React, { ReactElement, useRef } from 'react';
import { FeatureDirection } from './types';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

interface FeatureProps {
  content: ReactElement;
  direction?: FeatureDirection;
  illustration: ReactElement;
  title: string | ReactElement;
}

export const Feature = ({ content, direction = 'normal', illustration, title }: FeatureProps): ReactElement => {
  const featureRef = useRef(null);
  const { isVisible } = useVisibilityDetection(featureRef);

  return (
    <Styled.FeatureSection alignment={direction} isVisible={isVisible} ref={featureRef}>
      <Styled.ContentSection alignment={direction}>
        <Styled.Heading>
          <Styled.Title>{title}</Styled.Title>
        </Styled.Heading>
        <Styled.Content>{content}</Styled.Content>
      </Styled.ContentSection>
      <Styled.Figure>{illustration}</Styled.Figure>
    </Styled.FeatureSection>
  );
};
