import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from '../../types';

export const Wrapper = styled.section<Visibility>`
  
  max-width: ${1090 + dimensions.containerPadding * 2}px;
  margin: 60px auto 0 auto;
  padding: 0 ${dimensions.containerPadding}px;

  /* opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 40px;
  }
`;

export const Heading = styled.header`
  max-width: 720px;
  margin: 0 auto 65px auto;
  text-align: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
`;

export const List = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 48px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    grid-template-columns: 1fr;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  text-align: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ListFigure = styled.figure`
  display: flex;
  margin: 0 0 34px 0;
  padding: 0;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 20px;
  }
`;

export const ListTitle = styled.h3`
  margin: 0;
  font-size: ${dimensions.fontSize.medium}px;
  font-weight: 900;
`;

export const ListContent = styled.p`
  color: ${colors.lightGray};
  line-height: 1.625;
`;
