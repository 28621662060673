import React, { ReactElement } from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { animated, useTransition } from 'react-spring';
import { css } from '@emotion/react';
import { Content } from './Content';

interface ModalProps {
  illustration: string;
  isOpen: boolean;
  title: string;
  onDismiss: () => void;
}

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

export const Modal = React.memo(({ illustration, isOpen, onDismiss, title }: ModalProps): ReactElement => {
  const transitions = useTransition(isOpen, {
    from: { opacity: 0, y: 50 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 50 },
    config: { duration: 250 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <AnimatedDialogOverlay
          css={css`
            display: flex;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9999;
            align-items: center;
            justify-content: center;
            overflow: auto;
            will-change: opacity;
          `}
          onDismiss={onDismiss}
          style={{ opacity: styles.opacity }}
        >
          <AnimatedDialogContent
            aria-labelledby="dialog-title"
            css={css`
              width: 100vw;
              max-width: 1204px;
              padding: 10px 40px;
              max-height: calc(100vh - 80px);
              will-change: transform;

              @media screen and (max-width: 1023px) {
                padding-right: 20px;
                padding-left: 20px;
              }
            `}
            style={{
              transform: styles.y.to((value) => `translate3d(0px, ${value}px, 0px)`),
            }}
          >
            <Content illustration={illustration} onDismiss={onDismiss} title={title} />
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      ),
  );
});
