import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import IconModalClose from '../../assets/icon-modal-close.inline.svg';
import { ModalContentIllustration } from './ModalContentIllustration';

interface ContentProps {
  illustration: string;
  title: string;
  onDismiss: () => void;
}

export const Content = React.memo(({ illustration, title, onDismiss }: ContentProps): ReactElement => {
  return (
    <>
      <button
        css={css`
          width: 82px;
          height: 84px;
          padding: 0;
          position: absolute;
          top: 0;
          right: 10px;
          z-index: 100;
          border: 0;
          background: none;
          font-size: 0;
          cursor: pointer;

          @media screen and (max-width: 1023px) {
            transform: scale(0.6);
            transform-origin: top right;
          }

          &:hover,
          &:focus {
            opacity: 0.8;
          }

          &:focus {
            outline: 0;
          }
        `}
        onClick={onDismiss}
        type="button"
        aria-label="Close Dialog"
      >
        <IconModalClose />
        Close Dialog
      </button>
      <ModalContentIllustration illustration={illustration} title={title} />
    </>
  );
});
