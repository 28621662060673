import React, { ReactElement, ReactNode } from 'react';
import * as Styled from './styles';
import { css } from '@emotion/react';

interface TwoColSectionProps {
  heading?: ReactNode;
  leftColumn: ReactNode;
  styles?: any;
  rightColumn: ReactNode;
}

export const TwoColSection = ({ heading, leftColumn, styles, rightColumn }: TwoColSectionProps): ReactElement => {
  return (
    <Styled.Section
      css={css`
        ${styles}
      `}
    >
      {heading}
      <Styled.Content className='content'>
        <Styled.LeftColumn>{leftColumn}</Styled.LeftColumn>
        <Styled.RightColumn>{rightColumn}</Styled.RightColumn>
      </Styled.Content>
    </Styled.Section>
  );
};
